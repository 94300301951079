<template>
  <div>
    <div v-if="travellers.length === 0 && add === 0">
      <v-row>
        <v-img class="my-auto" :src="require(`@/assets/othertravellersempty.svg`)"></v-img>
      </v-row>
      <v-row>
        <p class="mx-auto grey--text">Add Info about people you are travelling with</p>
      </v-row>
      <v-row>
        <v-btn @click="add = 1 ;dialog = 0" color="primary white--text" right class="mb-3 mt-2 mx-auto">
          <v-icon left>mdi-plus-box</v-icon>
          Add travellers
        </v-btn>
      </v-row>
    </div>
    <div>
      <div class="d-flex justify-space-between primary--text">
        <h3 v-if="add === 0 && dialog === 0 && travellers.length > 0">Traveller Info</h3>
        <v-btn class="mb-3" v-if="add === 0 && dialog === 0 && travellers.length > 0" @click="add = 1" color="primary white--text" right>
          <v-icon left>mdi-plus-circle</v-icon>
          Add traveller
        </v-btn>
      </div>
        <v-card v-if="add === 0 && edit === 0 && travellers.length > 0" width="100%">
          <v-card-title class="primary--text">Other travellers</v-card-title>
          <v-simple-table>
            <tbody>
            <tr v-for="item in travellers" :key="item.id">
              <th style="font-size: 18px" class="font-weight-bold">{{ item.full_name }}</th>
              <td style="font-size: 16px">{{item.date_of_birth}}</td>
              <td style="font-size: 16px">{{ relations.find(x => x.value === item.relation_id).relation }}</td>
              <td>
                <v-btn @click="edit = 1;useTraveller(item.id)" color="primary white--text" text>
                  <v-icon>mdi-pencil-outline</v-icon> Edit
                </v-btn>
                <v-btn :disabled="travellerDeleteBtnLoad" :loading="travellerDeleteBtnLoad"
                       @click="deleteTraveller(item.id)" color="primary white--text"
                       text
                       class="ml-3">
                  <v-icon>mdi-delete</v-icon>Delete
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      <v-card v-if="add === 1" width="100%" class="pa-3">
        <v-card-title class="primary--text">Add Traveller</v-card-title>
        <v-form ref="newTravellerForm" v-model="valid">
          <v-row>
            <v-col cols="6">
              <label>First name</label>
            <v-text-field
              outlined
              class="mt-1"
              :rules="[rules.required]"
              v-model="newTravellerFirstName"
              label="Traveller first name"
            >
            </v-text-field>
            </v-col>
            <v-col cols="6">
              <label>Last name</label>
            <v-text-field
              outlined
              class="mt-1"
              :rules="[rules.required]"
              v-model="newTravellerLastName"
              label="Traveller Last name"
            >
            </v-text-field>
            </v-col>
            <v-col cols="6">
              <label>Date of birth</label>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="10"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    full-width
                    v-model="newTravellerDOB"
                    label="Date of birth"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    color="blue"
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    class="mt-1"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="newTravellerDOB"
                  @input="startDateMenu = false"
                  show-current
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <label>Relation</label>
            <v-select
              :items="relations"
              outlined
              item-text="relation"
              item-value="relation"
              class="mt-1"
              v-model="newTravellerRelation"
              label="Traveller relation">
            </v-select>
            </v-col>
          </v-row>
          <v-btn :loading="travellerBtnLoad" color="primary white--text" @click="addTraveller">
            <v-icon left>mdi-plus-box</v-icon>
            Add traveller
          </v-btn>
          <v-btn @click="add = 0" class="ml-1" text>Cancel</v-btn>
        </v-form>
      </v-card>
        <v-card v-if="edit === 1" width="100%" class="pa-3">
          <v-card-title class="primary--text">Edit Traveller</v-card-title>
          <v-form ref="editTravellerForm" v-model="valid1">
            <v-row>
              <v-col cols="6">
                <label>First name</label>
                <v-text-field
                  outlined
                  class="mt-1"
                  :rules="[rules.required]"
                  v-model="editingTravellerFirstName"
                  label="Traveller first name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Last name</label>
                <v-text-field
                  outlined
                  class="mt-1"
                  :rules="[rules.required]"
                  v-model="editingTravellerLastName"
                  label="Traveller Last name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Date of birth</label>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="10"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      full-width
                      v-model="editingTravellerDOB"
                      label="Date of birth"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      color="blue"
                      :rules="[rules.required]"
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      class="mt-1"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editingTravellerDOB"
                    @input="startDateMenu = false"
                    show-current
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <label>Relation</label>
                <v-select
                  :items="relations"
                  outlined
                  item-text="relation"
                  item-value="relation"
                  class="mt-1"
                  v-model="editingTravellerRelation"
                  label="Traveller relation">
                </v-select>
              </v-col>
            </v-row>
            <v-btn :loading="travellerEditBtnLoad" color="primary white--text"
                   @click="editTraveller(editingTravellerID)">
              <v-icon left>mdi-pencil-outline</v-icon>
              Edit traveller
            </v-btn>
            <v-btn  color="primary white--text"
                   @click="edit = 0" text>
              Cancel
            </v-btn>
          </v-form>
        </v-card>
    </div>
  </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      valid: true,
      valid1: true,
      travellers: [],
      add: 0,
      edit: 0,
      dialog: 0,
      travellerBtnLoad: false,
      travellerDeleteBtnLoad: false,
      travellerEditBtnLoad: false,
      newTravellerFirstName: null,
      newTravellerLastName: null,
      newTravellerDOB: null,
      baseTravellerDOB: null,
      baseTravellerFirstName: null,
      baseTravellerLastName: null,
      newTravellerRelation: null,
      baseTravellerRelation: null,
      editingTravellerFirstName: null,
      editingTravellerLastName: null,
      editingTravellerRelation: null,
      editingTravellerDOB: null,
      editingTravellerID: null,
      menu: null,
      menu1: null,
      relations: [
        {
          value: 1,
          relation: 'Friend'
        },
        {
          value: 2,
          relation: 'Wife'
        },
        {
          value: 3,
          relation: 'Son'
        },
        {
          value: 4,
          relation: 'Daughter'
        },
        {
          value: 5,
          relation: 'Relative'
        },
        {
          value: 6,
          relation: 'Roommate'
        },
        {
          value: 7,
          relation: 'Colleague'
        },
        {
          value: 8,
          relation: 'Coworker'
        }
      ],
      rules: { required: value => !!value || 'This field Required.' }
    }
  },
  methods: {
    deleteTraveller (id) {
      this.travellerDeleteBtnLoad = true
      user.deleteTraveller(id).then(res => {
        this.travellers = res.data.data
      }).catch(error => {
        this.$emit('error', error)
      }).finally(() => {
        this.travellerDeleteBtnLoad = false
        if (this.travellers.length === 0) {
          this.dialog = 1
        }
      })
    },
    useTraveller (id) {
      const traveller = this.travellers.find(x => x.id === id)
      this.editingTravellerID = id
      this.editingTravellerFirstName = traveller.name
      this.editingTravellerLastName = traveller.last_name
      this.editingTravellerDOB = traveller.date_of_birth
      this.baseTravellerDOB = traveller.date_of_birth
      this.baseTravellerFirstName = traveller.name
      this.baseTravellerLastName = traveller.last_name
      this.editingTravellerRelation = this.relations.find(y => y.value === traveller.relation_id).relation
      this.baseTravellerRelation = this.relations.find(y => y.value === traveller.relation_id).relation
    },
    editTraveller () {
      if (this.$refs.editTravellerForm.validate()) {
        if (this.valid1) {
          this.travellerEditBtnLoad = true
          const traveller = {}
          if (this.editingTravellerFirstName !== this.baseTravellerFirstName) {
            traveller.name = this.editingTravellerFirstName
          } else {
            traveller.name = this.baseTravellerFirstName
          }
          if (this.editingTravellerLastName !== this.baseTravellerLastName) {
            traveller.last_name = this.editingTravellerLastName
          } else {
            traveller.last_name = this.baseTravellerLastName
          }
          traveller.full_name = traveller.name + ' ' + traveller.last_name
          if (this.editingTravellerDOB !== this.baseTravellerDOB) {
            traveller.date_of_birth = this.editingTravellerDOB
          } else {
            traveller.date_of_birth = this.baseTravellerDOB
          }
          if (this.editingTravellerRelation !== this.baseTravellerRelation) {
            traveller.relation_name = this.editingTravellerRelation
          } else {
            traveller.relation_name = this.baseTravellerRelation
          }
          traveller.relative_id = this.editingTravellerID
          user.updateTraveller(traveller).then(res => {
            this.travellers = res.data.data
          }).catch(error => {
            this.$emit('error', error)
          }).finally(() => {
            this.$emit('success', 'Traveller edited')
            this.editingTravellerID = 0
            this.travellerEditBtnLoad = false
            this.editingTravellerRelation = 0
            this.editingTravellerDOB = 0
            this.editingTravellerFirstName = null
            this.editingTravellerLastName = null
            this.edit = 0
          })
        }
      }
    },
    addTraveller () {
      if (this.$refs.newTravellerForm.validate()) {
        if (this.valid) {
          this.travellerBtnLoad = true
          const traveller = {
            name: this.newTravellerFirstName,
            last_name: this.newTravellerLastName,
            full_name: this.newTravellerFirstName + ' ' + this.newTravellerLastName,
            date_of_birth: this.newTravellerDOB,
            relation_name: this.newTravellerRelation
          }
          user.addTraveller(traveller).then(res => {
            if (res.errors) {
              this.$emit('error', 'Something is missing')
            } else {
              this.$emit('success', 'Traveller added')
              this.travellers = res.data.data
            }
          }).catch(error => {
            this.$emit('error', error)
          }).finally(() => {
            this.travellerBtnLoad = false
            this.add = 0
            this.newTravellerDOB = null
            this.newTravellerFirstName = null
            this.newTravellerLastName = null
            this.newTravellerRelation = null
          })
        }
      }
    }
  },
  created () {
    user.otherTravellers().then(res => {
      this.travellers = res.data.data
    }).catch(error => {
      this.$emit('error', error)
    })
  }
}
</script>
